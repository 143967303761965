<template>
  <LoadingState v-if="isLoading" />
  <div :class="$style.dashboardMurid" v-else>
    <a-back-top />
    <h1>
      {{ user.nama }},
      <span v-if="schoolReligion === 'Islam'" :id="$style['salam-arab']"
        >السلام عليكم ورحمة الله وبركاته</span
      >
    </h1>
    <div :id="$style.dashboardContent">
      <a-row>
        <a-col :md="{ span: 24 }" :lg="{ span: 17 }">
          <div
            :style="{
              width: isMobile ? '100%' : '95%',
            }"
          >
            <div :id="$style.todayClass">
              <a-row style="margin-bottom: 2%">
                <a-col
                  :md="{ span: 15 }"
                  :lg="{ span: 15 }"
                  :id="$style.todayClassTitle"
                >
                  <h2 id="today-class">Today Class</h2>
                  <span v-if="todayClasses" :class="$style.additionalText"
                    >{{ todayClasses.count }} Class</span
                  >
                </a-col>
                <a-col
                  :class="$style.additionalText"
                  :style="{
                    'text-align': isMobile ? 'left' : 'right',
                  }"
                  :md="{ span: 9 }"
                  :lg="{ span: 9 }"
                  >{{ now }}</a-col
                >
              </a-row>
              <a-row v-if="todayClasses.count > 2">
                <VueSlickCarousel ref="carousel" v-bind="settings">
                  <a-col
                    :xs="{ span: 24 }"
                    :md="{ span: 12 }"
                    :lg="{ span: 8 }"
                    v-for="(item, index) in todayClasses.rows"
                    :key="index"
                  >
                    <CardTodayClassStudent
                      :key="index"
                      :item="item"
                      :isStudent="true"
                    />
                  </a-col>
                </VueSlickCarousel>
              </a-row>
              <template
                v-else-if="todayClasses.count > 0 && todayClasses.count <= 2"
              >
                <a-row class="mt-4" :gutter="16">
                  <a-col
                    :span="11"
                    v-for="(item, index) in todayClasses.rows"
                    :key="index"
                  >
                    <CardTodayClassStudent :item="item" :isStudent="true" />
                  </a-col>
                </a-row>
              </template>
              <div v-else class="my-5">
                <EmptyState
                  :heading="EmptyStateTodayClass.heading"
                  :description="EmptyStateTodayClass.description"
                />
              </div>
            </div>
            <div>
              <h2>
                Assignments in Progress
                <br v-if="isMobile" />
                <span
                  :class="$style.additionalText"
                  v-if="assignmentInProgresses"
                  >{{ assignmentInProgresses.length }} Session</span
                >
              </h2>
              <br />
              <a-row>
                <div
                  v-if="
                    assignmentInProgresses &&
                    assignmentInProgresses.length !== 0
                  "
                >
                  <!-- {{assignmentInProgresses.length}} -->
                  <a-col
                    :md="{ span: 12 }"
                    :sm="{ span: 24 }"
                    :lg="{ span: 12 }"
                    v-for="(item, index) in AIPSubjects"
                    :key="index"
                  >
                    <AssignmentInProgressStudent
                      :AIPSubject="item"
                      :totalWeeks="24"
                      :isDashboard="true"
                    />
                  </a-col>
                </div>
                <div class="my-5" v-else>
                  <EmptyState
                    :heading="EmptyStateAssignmentInProgress.heading"
                    :description="EmptyStateAssignmentInProgress.description"
                  />
                </div>
              </a-row>
            </div>
          </div>
        </a-col>
        <a-col v-if="institusi.is_announcement" :md="{ span: 24 }" :lg="{ span: 7 }">
          <DashboardAnnouncement @fetch-announcement="fetchAnnouncement" :to="{ name: 'Announcement Student' }" :announcementData="announcementData" />
        </a-col>
        <a-col :md="{ span: 24 }" :lg="{ span: 7 }">
          <a-card
            :class="$style.semesterProgress"
            :headStyle="{
              minHeight: '70px',
              display: 'flex',
              alignItems: 'center',
            }"
          >
            <h3 slot="title">Semester Progress</h3>
            <a-progress
              strokeColor="#40B983"
              :show-info="false"
              :percent="percentSemester"
            />
            <a-row type="flex" align="bottom" style="margin-top: 4%">
              <a-col :span="12">
                <p>
                  <span :class="$style['h2Text-green']">{{
                    semesterProgress.current_week
                  }}</span>
                  / {{ semesterProgress.total_week }} week
                </p>
              </a-col>
              <a-col :span="12">
                <p>
                  <span :class="$style['h2Text-black']">{{
                    semesterProgress.week_to_uts
                  }}</span>
                  {{ semesterProgress.week_to_uts === 1 ? "week" : "weeks" }} to
                  go
                </p>
              </a-col>
            </a-row>
          </a-card>
          <br />
          <a-card
            :headStyle="{
              minHeight: '70px',
              display: 'flex',
              alignItems: 'center',
            }"
          >
            <h3 slot="title">5 Newest Assignment Result</h3>
            <template v-if="newestAssignmentResults.length != 0">
              <!-- {{newestAssignmentResults}} -->
              <a-card
                :class="[$style.scoreLatest, $style.cardNewest]"
                v-for="(item, index) in newestAssignmentResults"
                :key="index"
                :style="{
                  backgroundColor: colorNewest(item['tugas_nilai']).background,
                }"
              >
                <a-row type="flex" align="middle">
                  <a-col :span="20">
                    <h3>{{ item["sesi.slot.mata_pelajaran.nama"] }}</h3>
                    <span :class="$style.topikAIP"
                      >Sess. {{ item["sesi.week.week"] }}/24 |
                      {{ item["sesi.topik.subtopik"] }}</span
                    >
                  </a-col>
                  <a-col :span="4">
                    <h2
                      :style="{ color: colorNewest(item['tugas_nilai']).text }"
                    >
                      {{ item["tugas_nilai"] }}
                    </h2>
                  </a-col>
                </a-row>
              </a-card>
            </template>
            <template v-else>
              <EmptyState
                :heading="EmptyStateAssignmentResult.heading"
                :description="EmptyStateAssignmentResult.description"
                class="my-5"
              />
            </template>
          </a-card>
        </a-col>
      </a-row>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import config from '@/config'
import VueSlickCarousel from 'vue-slick-carousel'
import EmptyState from '@/components/app/EmptyState'
import CardTodayClassStudent from '@/components/app/Dashboard/CardTodayClassStudent'
import LoadingState from '@/components/app/LoadingState'
import AssignmentInProgressStudent from '@/components/app/DetailSubject/CardAIP'
import { clearTag } from '@/helpers/regex.js'

const DashboardAnnouncement = () => import('@/components/app/DashboardAnnouncement')

const EmptyStateTodayClass = {
  heading: 'No Data',
  description: 'You have no class for today',
}
const EmptyStateAssignmentInProgress = {
  heading: 'No Data',
  description: 'You have no assignment in progress',
}

const EmptyStateAssignmentResult = {
  heading: 'No Data',
  description: 'You have no assignment result',
}

const EmptyStateAnnouncement = {
  heading: 'No Data',
  description: 'There\'s no announcement right now',
}

export default {
  data() {
    return {
      schoolReligion: '',
      moment,
      EmptyStateTodayClass,
      EmptyStateAssignmentInProgress,
      EmptyStateAssignmentResult,
      cardColor: '',
      announcementData: [],
      EmptyStateAnnouncement,
    }
  },
  components: {
    CardTodayClassStudent,
    VueSlickCarousel,
    EmptyState,
    LoadingState,
    AssignmentInProgressStudent,
    DashboardAnnouncement,
  },
  methods: {
    clearTag,
    async fetchAnnouncement() {
      try {
        const queryResult = await this.$store.dispatch('announcement/FETCH_ALL_ANNOUNCEMENT', { type: null, page: null, judul: null, limit: Number(2), role: 'Student' })
        // console.log('queryResult :>> ', queryResult)
        this.announcementData = queryResult.map(dat => {
          const tags = []
          let type = 'general'
          const { penerima_pengumumans: recipients } = dat
          if (recipients?.filter(pen => pen.id_kelas).length) type = 'id_kelas'
          else if (recipients?.filter(pen => pen.id_level).length) type = 'id_level'

          if (recipients?.length) {
            for (let i = 0; i < recipients?.length; i++) {
              const recipient = recipients[i]
              let tag = {}
              if (!recipient.id_kelas && !recipient.id_level) {
                tag = { title: 'General', color: 'green' }
                type = 'general'
              } else {
                if (recipient.id_kelas) {
                  const kelas = `${recipient.kela.level.nama}-${recipient.kela.simbol}`
                  tag = { title: kelas, color: 'orange' }
                  type = 'id_kelas'
                } else if (recipient.id_level) {
                  const level = recipient.level.nama
                  tag = { title: level, color: 'blue' }
                  type = 'id_level'
                }
              }
              tags.push(tag)
            }
          } else tags.push({ title: 'General', color: 'green' })
          return {
            ...dat,
            tag: tags,
            type,
            lastModified: moment(dat.updatedAt, 'YYYY-MM-DD HH:mm').format('DD MMM YYYY, HH:mm'),
            firstCreated: moment(dat.createdAt, 'YYYY-MM-DD HH:mm').format('DD MMM YYYY, HH:mm'),
            isEditable: false,
          }
        })
      } catch (err) {
        console.log('err fetchAnnouncement :>> ', err)
      }
    },
    toAllAnnouncement() {
      this.$router.push({ name: 'Announcement Student' })
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Announcement Student'],
      })
    },
    toDetail(idSesi) {
      this.$router.push({ name: 'Detail Schedule Student', params: { id: idSesi } })
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Schedule Student'],
        currentDetailScheduleMenu: ['assignments'],
      })
    },
    colorNewest(nilai) {
      if (nilai < 35) {
        return {
          background: '#FFE5E5',
          text: '#FF0000',
        }
      } else if (nilai >= 35 && nilai < 70) {
        return {
          background: '#FCF7E9',
          text: '#F89C47',
        }
      } else if (nilai >= 70) {
        return {
          background: '#CEF1E84D',
          text: ' #40B983',
        }
      } else {
        return {
          background: '#FFE5E5',
          text: '#FF0000',
        }
      }
    },
  },
  created() {
    this.schoolReligion = config.school.religion
    this.$store.dispatch('slot/FETCH_TODAY_CLASS_MURID', { idKelas: this.user.id_kelas })
    this.$store.dispatch('attendance/FETCH_ASSIGNMENT_IN_PROGRESS', { idMurid: this.user.id })
    this.$store.dispatch('slot/FETCH_SEMESTER_PROGRESS')
    this.$store.dispatch('attendance/FETCH_NEWEST_ASSIGNMENT_RESULT', { idMurid: this.user.id })
    if (this.institusi.is_announcement) this.fetchAnnouncement()
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading
    },
    todayClasses() {
      return this.$store.state.slot.kelasSesis
    },
    semesterProgress() {
      return this.$store.state.slot.semesterProgress
    },
    percentSemester() {
      const currentWeek = this.semesterProgress.current_week
      const totalWeek = this.semesterProgress.total_week
      return (currentWeek / totalWeek) * 100
    },
    newestAssignmentResults() {
      return this.$store.state.attendance.newestAssignmentResults
    },
    assignmentInProgresses() {
      return this.$store.state.attendance.assignmentInProgresses
    },
    user() { return this.$store.state.user.user },
    now() { return moment().format('DD MMM YYYY') },
    isMobile() {
      return this.$store.getters.isMobile
    },
    settings() {
      const settings = {
        arrows: true,
        centerMode: true,
        focusOnSelect: false,
        speed: 500,
      }
      const isMobile = this.$store.getters.isMobile
      const isTablet = this.$store.getters.isTablet
      // console.log(isTablet)
      if (!isTablet && isMobile) {
        settings.centerPadding = '-20px'
        settings.slidesToShow = 1
      } else if (isTablet && !isMobile) {
        // console.log('masuk 150')
        settings.centerPadding = '-150px'
        settings.slidesToShow = 2
      } else {
        settings.centerPadding = '25px'
        settings.slidesToShow = 2
        // this.settings.centerPadding = '-20px'
        // this.settings.slidesToShow = 1
      }

      return settings
    },
    AIPSubjects() {
      if (this.assignmentInProgresses.length) {
        const AIPSubjects = this.assignmentInProgresses.map(assignment => {
          return {
            attendances: [{
              submit_time: assignment['attendances.submit_time'],
              tugas_status: assignment['attendances.tugas_status'],
            }],
            id: assignment.id,
            slot: {
              id_mata_pelajaran: assignment['slot.mata_pelajaran.id'],
              mata_pelajaran: {
                nama: assignment['slot.mata_pelajaran.nama'],
              },
            },
            tipe_assignment: assignment.tipe_assignment,
            topik: {
              nama: assignment['topik.nama'],
              subtopik: assignment['topik.subtopik'],
            },
            tugas_deadline2: assignment.tugas_deadline2,
            tugas_deskripsi: assignment.tugas_deskripsi,
            week: {
              week: assignment['week.week'],
            },
          }
        })
        return AIPSubjects
      }
      return []
    },
    institusi() {
      return this.$store.state.master.institusi
    },
  },
}
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
