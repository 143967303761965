var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('LoadingState'):_c('div',{class:_vm.$style.dashboardMurid},[_c('a-back-top'),_c('h1',[_vm._v(" "+_vm._s(_vm.user.nama)+", "),(_vm.schoolReligion === 'Islam')?_c('span',{attrs:{"id":_vm.$style['salam-arab']}},[_vm._v("السلام عليكم ورحمة الله وبركاته")]):_vm._e()]),_c('div',{attrs:{"id":_vm.$style.dashboardContent}},[_c('a-row',[_c('a-col',{attrs:{"md":{ span: 24 },"lg":{ span: 17 }}},[_c('div',{style:({
            width: _vm.isMobile ? '100%' : '95%',
          })},[_c('div',{attrs:{"id":_vm.$style.todayClass}},[_c('a-row',{staticStyle:{"margin-bottom":"2%"}},[_c('a-col',{attrs:{"md":{ span: 15 },"lg":{ span: 15 },"id":_vm.$style.todayClassTitle}},[_c('h2',{attrs:{"id":"today-class"}},[_vm._v("Today Class")]),(_vm.todayClasses)?_c('span',{class:_vm.$style.additionalText},[_vm._v(_vm._s(_vm.todayClasses.count)+" Class")]):_vm._e()]),_c('a-col',{class:_vm.$style.additionalText,style:({
                  'text-align': _vm.isMobile ? 'left' : 'right',
                }),attrs:{"md":{ span: 9 },"lg":{ span: 9 }}},[_vm._v(_vm._s(_vm.now))])],1),(_vm.todayClasses.count > 2)?_c('a-row',[_c('VueSlickCarousel',_vm._b({ref:"carousel"},'VueSlickCarousel',_vm.settings,false),_vm._l((_vm.todayClasses.rows),function(item,index){return _c('a-col',{key:index,attrs:{"xs":{ span: 24 },"md":{ span: 12 },"lg":{ span: 8 }}},[_c('CardTodayClassStudent',{key:index,attrs:{"item":item,"isStudent":true}})],1)}),1)],1):(_vm.todayClasses.count > 0 && _vm.todayClasses.count <= 2)?[_c('a-row',{staticClass:"mt-4",attrs:{"gutter":16}},_vm._l((_vm.todayClasses.rows),function(item,index){return _c('a-col',{key:index,attrs:{"span":11}},[_c('CardTodayClassStudent',{attrs:{"item":item,"isStudent":true}})],1)}),1)]:_c('div',{staticClass:"my-5"},[_c('EmptyState',{attrs:{"heading":_vm.EmptyStateTodayClass.heading,"description":_vm.EmptyStateTodayClass.description}})],1)],2),_c('div',[_c('h2',[_vm._v(" Assignments in Progress "),(_vm.isMobile)?_c('br'):_vm._e(),(_vm.assignmentInProgresses)?_c('span',{class:_vm.$style.additionalText},[_vm._v(_vm._s(_vm.assignmentInProgresses.length)+" Session")]):_vm._e()]),_c('br'),_c('a-row',[(
                  _vm.assignmentInProgresses &&
                  _vm.assignmentInProgresses.length !== 0
                )?_c('div',_vm._l((_vm.AIPSubjects),function(item,index){return _c('a-col',{key:index,attrs:{"md":{ span: 12 },"sm":{ span: 24 },"lg":{ span: 12 }}},[_c('AssignmentInProgressStudent',{attrs:{"AIPSubject":item,"totalWeeks":24,"isDashboard":true}})],1)}),1):_c('div',{staticClass:"my-5"},[_c('EmptyState',{attrs:{"heading":_vm.EmptyStateAssignmentInProgress.heading,"description":_vm.EmptyStateAssignmentInProgress.description}})],1)])],1)])]),(_vm.institusi.is_announcement)?_c('a-col',{attrs:{"md":{ span: 24 },"lg":{ span: 7 }}},[_c('DashboardAnnouncement',{attrs:{"to":{ name: 'Announcement Student' },"announcementData":_vm.announcementData},on:{"fetch-announcement":_vm.fetchAnnouncement}})],1):_vm._e(),_c('a-col',{attrs:{"md":{ span: 24 },"lg":{ span: 7 }}},[_c('a-card',{class:_vm.$style.semesterProgress,attrs:{"headStyle":{
            minHeight: '70px',
            display: 'flex',
            alignItems: 'center',
          }}},[_c('h3',{attrs:{"slot":"title"},slot:"title"},[_vm._v("Semester Progress")]),_c('a-progress',{attrs:{"strokeColor":"#40B983","show-info":false,"percent":_vm.percentSemester}}),_c('a-row',{staticStyle:{"margin-top":"4%"},attrs:{"type":"flex","align":"bottom"}},[_c('a-col',{attrs:{"span":12}},[_c('p',[_c('span',{class:_vm.$style['h2Text-green']},[_vm._v(_vm._s(_vm.semesterProgress.current_week))]),_vm._v(" / "+_vm._s(_vm.semesterProgress.total_week)+" week ")])]),_c('a-col',{attrs:{"span":12}},[_c('p',[_c('span',{class:_vm.$style['h2Text-black']},[_vm._v(_vm._s(_vm.semesterProgress.week_to_uts))]),_vm._v(" "+_vm._s(_vm.semesterProgress.week_to_uts === 1 ? "week" : "weeks")+" to go ")])])],1)],1),_c('br'),_c('a-card',{attrs:{"headStyle":{
            minHeight: '70px',
            display: 'flex',
            alignItems: 'center',
          }}},[_c('h3',{attrs:{"slot":"title"},slot:"title"},[_vm._v("5 Newest Assignment Result")]),(_vm.newestAssignmentResults.length != 0)?_vm._l((_vm.newestAssignmentResults),function(item,index){return _c('a-card',{key:index,class:[_vm.$style.scoreLatest, _vm.$style.cardNewest],style:({
                backgroundColor: _vm.colorNewest(item['tugas_nilai']).background,
              })},[_c('a-row',{attrs:{"type":"flex","align":"middle"}},[_c('a-col',{attrs:{"span":20}},[_c('h3',[_vm._v(_vm._s(item["sesi.slot.mata_pelajaran.nama"]))]),_c('span',{class:_vm.$style.topikAIP},[_vm._v("Sess. "+_vm._s(item["sesi.week.week"])+"/24 | "+_vm._s(item["sesi.topik.subtopik"]))])]),_c('a-col',{attrs:{"span":4}},[_c('h2',{style:({ color: _vm.colorNewest(item['tugas_nilai']).text })},[_vm._v(" "+_vm._s(item["tugas_nilai"])+" ")])])],1)],1)}):[_c('EmptyState',{staticClass:"my-5",attrs:{"heading":_vm.EmptyStateAssignmentResult.heading,"description":_vm.EmptyStateAssignmentResult.description}})]],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }